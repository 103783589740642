const state = {
  lineInfo: null
}

const actions = {
  setLineInfo ({ commit }, payload) {
    commit('SET_LINE_INFO', payload)
  }
}

const mutations = {
  SET_LINE_INFO (state, payload) {
    state.lineInfo = payload
  }
}

const getters = {
  lineInfo: (state) => state.lineInfo
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
