import HttpRequest from './http-request'
import { getToken } from '../utils/cookies'

class LineUsersProvider extends HttpRequest {
  getLineUsers (query) {
    const token = getToken()
    return this.get(
      'line-users',
      query,
      {
        headers: {
          Authorization: `Basic ${token}`
        }
      }
    )
  }

  create (payload) {
    return this.post('public/line-users', payload)
  }
}

export default LineUsersProvider
