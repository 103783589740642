<template>
  <div class="container">
    <form
      class="search-bar"
      @submit.prevent="getLineUsers(true)">
      <div>
        <label>
          Start
          <input
            v-model="query.startDate"
            type="date">
        </label>
        <label>
          End
          <input
            v-model="query.endDate"
            type="date">
        </label>
      </div>
      <div>
        <input
          v-model="query.search"
          type="text"
          placeholder="Search">
        <button
          :disabled="loading"
          type="submit">
          Search
        </button>
      </div>
    </form>
    <div class="summary-container">
      <div class="summary">
        <span>Total: {{ totalItem }}</span>
        <button
          :disabled="loading"
          @click="exportData()">
          Export
        </button>
      </div>
      <div class="paginate">
        <button
          :disabled="!canPrev"
          @click="prev()">
          PREV
        </button>
        <span>
          {{ `${showFrom} - ${showTo}` }}
        </span>
        <button
          :disabled="!canNext"
          @click="next()">
          NEXT
        </button>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th
            v-for="(header, h) in headers"
            :key="h"
            scope="col">
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(user, i) in lineUsers"
          :key="i">
          <td
            v-for="(header, h) in headers"
            :key="h"
            :data-label="header.label">
            <img
              v-if="header.value === 'pictureUrl'"
              :src="user[header.value]"
              alt="Profile"
              class="responsive-img">
            <span v-else-if="header.value === 'createdAt'">
              {{ getDateFormat(user[header.value]) }}
            </span>
            <span v-else>
              {{ user[header.value] }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { ExportToCsv } from 'export-to-csv'
  import LineUsersProvider from '../resources/line-users.provider'
  import { encodeToken, getToken, setToken } from '../utils/cookies'

  const LineUsersService = new LineUsersProvider()

  export default {
    data () {
      return {
        loading: false,
        query: {
          page: 1,
          limit: 25,
          search: '',
          startDate: '',
          endDate: ''
        },
        lineUsers: [],
        totalPage: 0,
        totalItem: 0,
        headers: [
          {
            label: 'Date',
            value: 'createdAt'
          },
          {
            label: 'User Id',
            value: 'userId'
          },
          {
            label: 'Display name',
            value: 'displayName'
          },
          {
            label: 'Picture',
            value: 'pictureUrl'
          },
          {
            label: 'Status',
            value: 'statusMessage'
          }
        ]
      }
    },
    computed: {
      canPrev () {
        return this.query.page > 1
      },
      canNext () {
        return this.query.page < this.totalPage
      },
      showFrom () {
        if (this.query.page === 1) {
          return 1
        }

        return (this.query.page - 1) * this.query.limit
      },
      showTo () {
        const base = (this.query.page - 1) * this.query.limit

        return base + this.lineUsers.length
      }
    },
    mounted () {
      this.query.startDate = this.$dayjs().format('YYYY-MM-DD')
      this.query.endDate = this.$dayjs().add(7, 'd').format('YYYY-MM-DD')
      this.getLineUsers()
    },
    methods: {
      async getLineUsers (isClear = false) {
        try {
          this.loading = true
          this.$store.dispatch('Loading/setLoading', true)

          if (isClear) {
            this.lineUsers = []
            this.query.page = 1
          }

          const token = getToken()

          if (!token) {
            throw Error('Missing token')
          }

          const { data } = await LineUsersService.getLineUsers({
            page: this.query.page,
            limit: this.query.limit,
            search: this.query.search,
            startDate: this.$dayjs(`${this.query.startDate} 00:00`).utc().format(),
            endDate: this.$dayjs(`${this.query.endDate} 23:59`).utc().format()
          })

          this.lineUsers = data?.results || []
          this.totalItem = data.total
          this.totalPage = data.pages
        } catch (error) {
          console.error('getLineUsers', error)
          this.loginForm()
        } finally {
          this.loading = false
          this.$store.dispatch('Loading/setLoading', false)
        }
      },
      async loginForm () {
        const { value: formValues } = await this.$swal.fire({
          title: 'LOGIN',
          html:
            '<input id="swal-input1" class="swal2-input" placeholder="Username">'
            + '<input id="swal-input2" class="swal2-input" type="password" placeholder="Password">',
          focusConfirm: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          preConfirm: () => ([
            document.getElementById('swal-input1').value,
            document.getElementById('swal-input2').value
          ])
        })

        if (formValues) {
          const encoded = encodeToken(formValues[0], formValues[1])
          setToken(encoded)
          this.getLineUsers()
        }
      },
      getDateFormat (date) {
        if (!date) {
          return '-'
        }

        return this.$dayjs(date).format('DD/MM/YYYY HH:mm')
      },
      prev () {
        if (this.canPrev) {
          this.query.page--
          this.getLineUsers()
        }
      },
      next () {
        if (this.canNext) {
          this.query.page++
          this.getLineUsers()
        }
      },
      async exportData () {
        try {
          this.$store.dispatch('Loading/setLoading', true)

          const { data } = await LineUsersService.getLineUsers({
            search: this.query.search,
            limit: 999999,
            startDate: this.$dayjs(`${this.query.startDate} 00:00`).utc().format(),
            endDate: this.$dayjs(`${this.query.endDate} 23:59`).utc().format()
          })

          const items = []
          data.results.forEach((user) => items.push({
            'Date': this.$dayjs(user.createdAt).format('DD/MM/YYYY HH:mm'),
            'User Id': user.userId,
            'Display name': user.displayName,
            'Picture': user.pictureUrl,
            'Status': user.statusMessage
          }))

          const options = {
            filename: 'LINE_Users',
            showLabels: true,
            useKeysAsHeaders: true
          }

          const csvExporter = new ExportToCsv(options)
          csvExporter.generateCsv(items)
        } catch (error) {
          console.error('exportData', error)
        } finally {
          this.$store.dispatch('Loading/setLoading', false)
        }
      }
    }
  }
</script>

<style>
.swal2-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.swal2-html-container {
  margin: 0 !important;
}
.swal2-styled.swal2-confirm {
  padding: 0.8rem 2.5rem;
}
</style>

<style scoped>
.container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 960px;
}
.responsive-img {
  width: 80px;
  height: auto;
  border-radius: 5px;
}

.search-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.search-bar input {
  height: 20px;
  margin: 0 3px;
}

.search-bar button {
  border: 1px solid #555;
  border-radius: 3px;
  height: 24px;
  padding: 0 1rem;
  cursor: pointer;
}

.summary-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.summary-container .summary {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.summary-container .summary button {
  border: 1px solid #555;
  border-radius: 3px;
  height: 24px;
  padding: 0 1rem;
  cursor: pointer;
  margin-left: 6px;
}

.summary-container .paginate {
  display: flex;
  flex-direction: row;
}

.summary-container .paginate button {
  border: 1px solid #555;
  border-radius: 3px;
  height: 24px;
  padding: 0 0.3rem;
  cursor: pointer;
  margin-left: 6px;
}

.summary-container .paginate span {
  margin-left: 6px;
  width: 50px;
  line-height: 1.7rem;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
  word-wrap: break-word;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .search-bar {
    flex-direction: column;
  }

  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>
