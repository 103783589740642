<template>
  <div class="container">
    <div
      v-if="!loading"
      class="thank-img">
      <span>
        "ขอบคุณที่เป็นเพื่อนกับ Porsa นะครับ"<br>
        เส้นทางที่แบ่งปันความรู้เพื่อสุขภาพหมูที่ดี
      </span>
      <img
        src="../assets/pig-way.png"
        alt="THANKYOU">
    </div>
    <div
      v-else
      class="loader">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LineUsersProvider from '../resources/line-users.provider'

const LineUsersService = new LineUsersProvider()

export default {
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      lineInfo: 'LineProfile/lineInfo'
    })
  },
  mounted () {
    setTimeout(() => this.loginLineUser(), 1000)
  },
  methods: {
    async loginLineUser () {
      try {
        if (!this.lineInfo) {
          throw Error('Missing profile')
        }

        await LineUsersService.create(this.lineInfo)
      } catch (error) {
        console.error('loginLineUser', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .thank-img {
  position: relative;
  max-width: 960px;
  width: 80%;
  height: auto;
}
.container .thank-img span {
  position: absolute;
  font-size: 3vw;
}
.container .thank-img img {
  width: 100%;
  height: auto;
}
.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #555;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (max-width: 600px) {
  .container .thank-img span {
    font-size: 4.5vw;
  }
}
</style>
