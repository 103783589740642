import Vue from 'vue'
import Vuex from 'vuex'
import LineProfile from './modules/line-profile'
import Loading from './modules/loading'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    LineProfile,
    Loading
  }
})
