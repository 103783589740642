<template>
  <div
    v-if="ready"
    id="app">
    <loading v-if="loading" />
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Liff from '@line/liff'
import loading from './components/loading.vue'

export default {
components: { loading },
  data () {
    return {
      ready: false
    }
  },
  computed: {
    ...mapGetters({
      loading: 'Loading/loading'
    })
  },
  async mounted () {
    try {
      await Liff.init({ liffId: process.env.VUE_APP_LIFF_ID })

      if (!Liff.isLoggedIn()) {
        Liff.login()
      }

      const profile = await Liff.getProfile()
      await this.$store.dispatch('LineProfile/setLineInfo', profile)

      this.ready = true
    } catch (error) {
      console.error('mounted', error)
    }
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
