import * as Cookies from 'js-cookie'

export const getToken = () => Cookies.getJSON(process.env.VUE_APP_TOKEN)

export const setToken = (token) => {
  const now = new Date().getTime()

  Cookies.set(process.env.VUE_APP_TOKEN, token, {
    expires: new Date(now + (60 * 60000))
  })

  return true
}

export const clearCookie = () => {
  Cookies.remove(process.env.VUE_APP_TOKEN)
}

export const encodeToken = (username, password) => btoa(`${username}:${password}`)

export default {
  encodeToken,
  getToken,
  setToken,
  clearCookie
}
